<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

        <!--        <tchtree v-model="searchForm.class_ids" @change="getlist" :isAll="1"></tchtree>-->
        <el-input v-model="searchForm.stu_name" size="small" placeholder="创建人"
          style="max-width: 200px; margin-right: 10px;" />
        <el-input v-model="searchForm.issue_name" size="small" placeholder="问题标题"
          style="max-width: 200px; margin-right: 10px; margin-left: 10px" />
        <el-select v-model="searchForm.issue_type" size="small" placeholder="问题类型"
          style="max-width: 200px; margin-right: 10px; margin-left: 10px">
          <el-option label="工作" value="工作"></el-option>
          <el-option label="生活" value="生活"></el-option>
          <el-option label="教程" value="教程"></el-option>
        </el-select>
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>
    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column prop="issue_name" label="标题" width="200"></el-table-column>
      <el-table-column prop="issue_content" label="内容" min-width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="issue_type" label="类型" width="80"></el-table-column>
      <el-table-column prop="scope" label="是否公开" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.scope == '1'">不公开</span>
          <span v-if="scope.row.scope == '0'">公开</span>
        </template>
      </el-table-column>
      <el-table-column prop="location" label="学生位置" width="250">
        <template slot-scope="scope">{{ (scope.row.location&&scope.row.location.location)||'-' }} </template>
      </el-table-column>
      <el-table-column prop="stu_name" label="学生姓名" width="100"></el-table-column>
      <el-table-column prop="phone" label="学生电话" width="135"></el-table-column>
      <el-table-column prop="cdate" label="发布时间" width="185"></el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-button type="text" size="" @click="review(scope.row)" style="margin: 0 !important">查看问题</el-button>
            <el-button class="ml10" type="text" size="" @click="reviewAnswer(scope.row)"
              style="color:#67c23a;">查看回答</el-button>
            <el-button class="ml10" type="text" size="small" @click="del(scope.row)"
              style="color:#ff5500;">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog title="校友问答详情" :visible.sync="dialogVisible" custom-class="cus_dialog" width="60%">
      <el-form ref="form" :model="ruleForm" label-width="120px">
        <el-form-item label="问答标题："> {{ ruleForm.issue_name || '-' }} </el-form-item>
        <el-form-item label="问答内容："> {{ ruleForm.issue_content || '-' }} </el-form-item>
        <el-form-item label="问答类型："> {{ ruleForm.issue_type || '-' }} </el-form-item>
        <el-form-item label="是否公开："> {{ ruleForm.scope == 1 ? 不公开 :'公开' }} </el-form-item>
        <el-form-item label="学生位置："> {{ (ruleForm.location&&ruleForm.location.location) || '-' }} </el-form-item>
        <el-form-item label="学生姓名："> {{ ruleForm.stu_name || '-' }} </el-form-item>
        <el-form-item label="学生电话："> {{ ruleForm.phone || '-' }} </el-form-item>
        <el-form-item label="发布时间："> {{ ruleForm.cdate || '-' }} </el-form-item>




        <el-form-item v-if="ruleForm.appendix && ruleForm.appendix.length > 0" label="附件图片：">
          <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
            v-for="(img, idx) in ruleForm.appendix" :key="idx">
            <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
              :preview-src-list="[img.fileUrl || img.src]" fit="contain"></el-image>
          </div>
        </el-form-item>
        <el-form-item v-if="!ruleForm.appendix || ruleForm.appendix.length == 0" label="附件图片：">-
        </el-form-item>


        <el-form-item v-if="ruleForm.status != 0" label="审核状态：">
          <span style="color: #67c23a;" v-if="ruleForm.status == 1">通过</span>
          <span style="color: #ff5500;" v-if="ruleForm.status == -1">未通过</span>
        </el-form-item>
        <el-form-item v-if="ruleForm.status != 0" label="审核意见：">
          {{ ruleForm.remark || '-' }}
        </el-form-item>
        <el-form-item v-if="ruleForm.status != 0" label="审核人：">
          {{ ruleForm.sp_username || '-' }}
        </el-form-item>

      </el-form>
      <div v-if="ruleForm.status == 0" slot="footer" class="flexEnd" style="width: 100%;">
        <span style="white-space: nowrap;">审核意见：</span>
        <el-input v-model="ruleForm.remark" size="small" type="textarea" :autosize="{ minRows: 1 }" maxlength="200"
          placeholder="审核驳回时请填写驳回原因哦~" style="margin-right: 20px;"></el-input>
        <el-button type="danger" @click="onSubmit(-1)" size="small">审核驳回</el-button>
        <el-button type="success" @click="onSubmit(1)" size="small">审核通过</el-button>
      </div>
    </el-dialog>
    <el-dialog title="校友问答回答详情" :visible.sync="dialogVisible_answer" custom-class="cus_dialog" width="60%">
      <div v-for="(item,index) in answerlist">
        <el-form label-width="120px">
          <el-form-item label="问复人："> {{ item.stu_name || '-' }} </el-form-item>
          <el-form-item label="问复内容："> {{ item.comment || '-' }} </el-form-item>
          <el-form-item v-if="item.appendix && item.appendix.length > 0" label="附件图片：">
            <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
              v-for="(img, idx) in item.appendix" :key="idx">
              <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                :preview-src-list="[img.fileUrl || img.src]" fit="contain"></el-image>
            </div>
          </el-form-item>
          <el-form-item label="问复时间："> {{ item.cdate || '-' }} </el-form-item>
        </el-form>
        <el-divider></el-divider>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../../com/tchTree.vue";
export default {
  components: { tchtree },
  data() {
    return {

      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      dialogVisible: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {},
      ruleForm: {},
      dialogVisible_answer: false,
      answerlist:[],
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    onView(row) {
      this.visible = true
      this.detailForm = row
    },
    getYears() {
      return new Promise((resolve, reject) => {
        this.$http.post("/api/njwx_stu_years").then(res => {
          this.YearList = res.data
          this.searchForm.year = res.data[0].gyear
          resolve()
        }).catch(err => {
          resolve()

        })
      })
    },
    getlist() {
      this.$http
        .post("/api/sys_forum_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          stu_name: this.searchForm.stu_name,
          issue_name: this.searchForm.issue_name,
          issue_type: this.searchForm.issue_type,
          status: this.searchForm.status,
          type: 1,
        })
        .then((res) => {

          res.data.data && res.data.data.map(a => {
            if (a.location && this.isJSON(a.location)) {
              a.location = JSON.parse(a.location)
            }
          })
          this.dataList = res.data.data;
          this.page = res.data.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    review1(e) {
      this.ruleForm.id = e.id;
      this.ruleForm.activity_status = undefined
      this.dialogVisible = true;
    },
    review(e, type) {
      console.log(182, e)
      if (e.appendix && this.isJSON(e.appendix)) {
        e.appendix = JSON.parse(e.appendix)
      }
      this.ruleForm = e
      this.dialogVisible = true;
  
    },
    reviewAnswer(e) {
      console.log(182, e)
      this.$http.post('/api/bygz_stu_forum_comment_list', {
        forum_id: Number(e.id)
      }).then(res => {
        console.log(res)
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map(a => {
            if (a.appendix && this.isJSON(a.appendix)) {
              a.appendix = JSON.parse(a.appendix)
            }
          })
          this.dialogVisible_answer = true;
          this.answerlist =  res.data.data
        } else {
          this.$message.warning('该问题暂无回答~')
        }
      })

    },
    del(row) {
      this.$confirm('此操作不可恢复，是否确认删除？', {
        type: 'warning'
      }).then(() => {
        this.$http
          .post("/api/sys_forum_delete", { id: row.id })
          .then((res) => {
            this.$message.success('删除成功')
            this.getlist();
          });
      })


    },
    onSubmit(status) {
      this.$http
        .post("api/check_activity",{
          id: this.ruleForm.id,
          status,
          remark: this.ruleForm.remark
        })
        .then((res) => {
          this.dialogVisible = false;
          this.getlist();
        });
    },
  },
};
</script>
